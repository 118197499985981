import { gql, useQuery } from '@apollo/client';
import { buildURL } from 'react-imgix';
import Head from 'next/head';

import config from 'config';
import { initializeApollo, addApolloState } from 'lib/apolloClient';
import { SiteProvider } from 'lib/context';
import Header from 'components/Header';
import Hero from 'components/Hero';
import About from 'components/About';
import Services from 'components/Services';
import Team from 'components/Team';
import Contact from 'components/Contact';
import Footer from 'components/Footer';

const GET_DATA = gql`
  query {
    teamList(site: "shm", sort: "meta:order_shm") {
      id
      title
      featuredImage {
        sourceUrl
        altText
      }
      teamMemberMeta {
        linkedinUrl
        email
      }
    }
    soundHillMusicSettings {
      SHMSettings {
        hero {
          clientPortalUrl
          content
          bgImage {
            sourceUrl
            altText
          }
        }
        about {
          content
          bgImage {
            sourceUrl
            altText
          }
        }
        services {
          name
          content
          image {
            sourceUrl
            altText
          }
        }
        contact {
          usPhone
          ukPhone
          locations
          email
          formId
          bgImage {
            sourceUrl
            altText
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const { data, loading } = useQuery(GET_DATA);

  if (!data && loading) return null;

  const { hero, about, services, contact } = data.soundHillMusicSettings.SHMSettings;
  const team = data?.teamList;

  const image = config('/opengraph/image');
  const imageUrl = image.replace(config('/mediaUrls/s3'), config('/mediaUrls/imgix'));
  const facebookImage = buildURL(imageUrl, {
    disableLibraryParam: true,
    aggressiveLoad: true,
    w: 1200,
    h: 627,
    q: 80,
    crop: 'faces,edges',
    fit: 'crop',
  });

  const twitterImage = buildURL(imageUrl, {
    disableLibraryParam: true,
    aggressiveLoad: true,
    w: 280,
    h: 150,
    q: 80,
    crop: 'faces,edges',
    fit: 'crop',
  });

  return (
    <SiteProvider {...{ hero, about, services, contact, team }}>
      <Head>
        <meta charSet="utf-8" key="charset" />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no,shrink-to-fit=no"
        />

        <title>{config('/opengraph/title')}</title>
        <meta key="description" name="description" content={config('/opengraph/description')} />

        <meta key="og-type" name="og:type" content="website" />
        <meta key="og-url" name="og:url" content={config('/opengraph/url')} />
        <meta key="og-title" name="og:title" content={config('/opengraph/title')} />
        <meta
          key="og-description"
          name="og:description"
          content={config('/opengraph/description')}
        />
        <meta key="og-image" name="og:image" content={facebookImage} />

        <meta key="twitter-card" name="twitter:card" content="summary" />
        <meta key="twitter-url" name="twitter:url" content={config('/opengraph/url')} />
        <meta key="twitter-title" name="twitter:title" content={config('/opengraph/title')} />
        <meta
          key="twitter-description"
          name="twiter:description"
          content={config('/opengraph/description')}
        />
        <meta key="twitter-image" name="twitter:image" content={twitterImage} />

        <link rel="icon" href={config('/favicon')} type="image/png" />
        <link rel="shortcut icon" href={config('/favicon')} type="image/png" />
      </Head>
      <Header />
      <Hero />
      <About />
      <Services />
      <Team />
      <Contact />
      <Footer />
    </SiteProvider>
  );
};

export async function getServerSideProps() {
  const client = initializeApollo();

  const { data } = await client.query({ query: GET_DATA });

  // Issue retrieving data
  if (!data?.soundHillMusicSettings?.SHMSettings) return { notFound: true };

  return addApolloState(client, { props: {} });
}

export default IndexPage;
