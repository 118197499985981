import PropTypes from 'prop-types';
import { scroller } from 'react-scroll';

const ScrollLink = ({ href, children, ...props }) => (
  <a
    {...props}
    href={href}
    onClick={() =>
      scroller.scrollTo(href.replace('#', ''), {
        duration: 1200,
        offset: -60,
        smooth: true,
      })
    }
  >
    {children}
  </a>
);

ScrollLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

ScrollLink.defaultProps = {};

export default ScrollLink;
