import React from 'react';

import { useSite } from 'lib/context';
import Wave from '../svg/wave.svg';

const Services = () => {
  const { services } = useSite();

  return (
    <>
      <div className="container pt-24" id="services">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-12">
          {services.map((service) => (
            <div key={service.name} className="flex gap-x-8">
              <div className="w-12 sm:w-16 flex-shrink-0">
                <img
                  src={service.image.sourceUrl}
                  alt={service.image.altText ?? ''}
                  className="block max-w-full mx-auto"
                />
              </div>
              <div>
                <h2 className="font-bold text-lg">{service.name}</h2>
                <p className="mt-2 text-sm text-gray-600 leading-loose">{service.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Wave className="block w-full" style={{ marginBottom: '-0.4%' }} />
    </>
  );
};

Services.propTypes = {};

Services.defaultProps = {};

export default Services;
