import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import { motion } from 'framer-motion';

import config from 'config';
import Button from './Button';

const schema = yup.object().shape({
  contact_name: yup.string().required('This field is required'),
  contact_email: yup.string().email('Must be a valid email').required('This field is required'),
  contact_message: yup.string().required('This field is required'),
});

const ContactForm = ({ id }) => {
  const [status, setStatus] = useState('default');
  const { handleSubmit, register, setError, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const { errors } = formState;

  const onSubmit = useCallback(
    async (values) => {
      try {
        const data = new FormData();
        Object.entries(values).forEach(([key, value]) => data.set(key, value));

        const apiUrl = config('/apiUrl');
        const { data: response } = await axios({
          method: 'post',
          url: `${apiUrl}/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`,
          config: { headers: { 'Content-Type': 'multipart/form-data' } },
          data,
        });

        // Server validation failed
        if (response.invalidFields) {
          response.invalidFields.forEach(({ into: field, message }) =>
            setError(field.replace('span.wpcf7-form-control-wrap.', ''), {
              message,
              type: 'server-validation',
            })
          );
          return;
        }

        setStatus('success');
      } catch (err) {
        // Request failed for some reason
        console.warn('Form submission error', err);
        setStatus('error');
      }
    },
    [id, setError, setStatus]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {status === 'success' ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="bg-white rounded-lg shadow-md p-8 text-center"
        >
          <h3 className="text-xl font-bold">Thanks!</h3>
          <p className="text-gray-600 mt-2 leading-snug">
            Your message has been submitted. We&apos;ll be in touch soon!
          </p>
        </motion.div>
      ) : (
        <div className="flex flex-col items-stretch gap-y-6">
          <div>
            <label htmlFor="contact_name" className="block mb-2">
              Name
            </label>
            <input
              type="text"
              placeholder="ie. John Smith"
              className="w-full rounded bg-white border border-gray-300 py-2 px-3"
              {...register('contact_name')}
            />
            <ErrorMessage
              name="contact_name"
              errors={errors}
              render={({ message }) => (
                <p className="inline-block bg-gray-800 text-sm text-white py-1 px-2 mt-2">
                  {message}
                </p>
              )}
            />
          </div>
          <div>
            <label htmlFor="contact_email" className="block mb-2">
              Email
            </label>
            <input
              type="email"
              placeholder="ie. john.smith@example.com"
              className="w-full rounded bg-white border border-gray-300 py-2 px-3"
              {...register('contact_email')}
            />
            <ErrorMessage
              name="contact_email"
              errors={errors}
              render={({ message }) => (
                <p className="inline-block bg-gray-800 text-sm text-white py-1 px-2 mt-2">
                  {message}
                </p>
              )}
            />
          </div>
          <div>
            <label htmlFor="contact_message" className="block mb-2">
              Message
            </label>
            <textarea
              placeholder="Start typing here..."
              className="w-full rounded bg-white border border-gray-300 py-2 px-3 h-40"
              {...register('contact_message')}
            />
            <ErrorMessage
              name="contact_message"
              errors={errors}
              render={({ message }) => (
                <p className="inline-block bg-gray-800 text-sm text-white py-1 px-2 mt-2">
                  {message}
                </p>
              )}
            />
          </div>
          <div>
            {status === 'error' && (
              <motion.p
                className="mb-4 text-sm"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
              >
                Something went wrong when processing your request. <br />
                Please try again later.
              </motion.p>
            )}
            <Button color="pink" type="submit">
              Send
              <FontAwesomeIcon icon={faLongArrowRight} size="1x" className="ml-3" />
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};

ContactForm.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

ContactForm.defaultProps = {};

export default ContactForm;
