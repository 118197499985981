import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import { useSite } from 'lib/context';
import Link from './Link';

const Team = () => {
  const { team } = useSite();
  return (
    <div className="bg-gray-100 py-24" id="team">
      <div className="container">
        <h2 className="font-bold text-2xl">Team</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 mt-12">
          {team.map((member) => {
            const [first, ...parts] = member.title.split(' ');
            const last = parts.join(' ');

            return (
              <div key={member.id} className="relative">
                <img
                  src={member.featuredImage.sourceUrl}
                  alt={member.featuredImage.altText ?? ''}
                  className="block w-56 h-72 filter saturate-0 object-center object-cover"
                />
                <div className="absolute z-10 left-28 top-40">
                  <div>
                    <p className="font-bold inline-block bg-white whitespace-nowrap p-1">{first}</p>
                  </div>
                  <div className="mt-px ml-4">
                    <p className="text-2xl font-bold inline-block bg-white whitespace-nowrap p-1">
                      {last}
                    </p>
                  </div>
                  {member.teamMemberMeta.linkedinUrl && (
                    <div className="mt-px ml-8 flex gap-x-px">
                      <Link
                        href={member.teamMemberMeta.linkedinUrl}
                        className="w-7 h-7 bg-pink-500 hover:bg-pink-700 transition-colors duration-200 text-white flex justify-center items-center"
                      >
                        <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

Team.propTypes = {};

Team.defaultProps = {};

export default Team;
