import BlackHillRecordsLogo from '@globobeet/roundhill-toolkit/svg/BlackHillRecords.svg';
import RoundHillMusicLogo from '@globobeet/roundhill-toolkit/svg/RoundHillMusic.svg';
import RoundHillRecordsLogo from '@globobeet/roundhill-toolkit/svg/RoundHillRecords.svg';
import RidentLogo from '@globobeet/roundhill-toolkit/svg/RidentRoyalties.svg';

import Link from './Link';

const Footer = () => (
  <div className="bg-gray-900">
    <div className="container py-8">
      <div className="grid grid-cols-4 gap-8 py-4 sm:pt-12 sm:pb-16 max-w-4xl mx-auto">
        <Link
          href="https://roundhillmusic.com"
          className="text-gray-500 hover:text-white transition-colors duration-200"
        >
          <RoundHillMusicLogo className="block h-12 max-w-full mx-auto" />
        </Link>
        <Link
          href="https://roundhillmusic.com/roster?category=recording"
          className="text-gray-500 hover:text-white transition-colors duration-200"
        >
          <RoundHillRecordsLogo className="block h-12 max-w-full mx-auto" />
        </Link>
        <Link
          href="https://blackhillrecords.com"
          className="text-gray-500 hover:text-white transition-colors duration-200"
        >
          <BlackHillRecordsLogo className="block h-12 max-w-full mx-auto" />
        </Link>
        <Link
          href="https://ridentroyalties.com/"
          className="text-gray-500 hover:text-white transition-colors duration-200"
        >
          <RidentLogo className="block h-12 max-w-full mx-auto" />
        </Link>
      </div>

      <p className="text-center text-white text-sm">
        Copyright &copy;{new Date().getFullYear()} - Sound Hill
      </p>
    </div>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
