import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapMarkerAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons';

import { useSite } from 'lib/context';
import ContactForm from './ContactForm';
import Link from './Link';

const Contact = () => {
  const { contact } = useSite();

  return (
    <div className="relative" id="contact">
      <div className="absolute inset-0">
        <img
          src={contact.bgImage.sourceUrl}
          alt={contact.bgImage.altText}
          className="block w-full h-full filter saturate-0 object-center object-cover opacity-25"
        />
      </div>
      <div className="relative container py-24">
        <h2 className="font-bold text-2xl mb-8">Contact</h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12">
          <div className="max-w-md">
            <div className="flex flex-col gap-y-4">
              <div className="flex items-center text-sm">
                <FontAwesomeIcon
                  icon={faPhone}
                  size="lg"
                  className="text-pink-600 mr-3"
                  fixedWidth
                />
                <p className="text-gray-500">
                  <span className="font-bold text-xl text-gray-800 mr-1">{contact.usPhone}</span>
                  US
                </p>
              </div>
              <div className="flex items-center text-sm">
                <FontAwesomeIcon
                  icon={faPhone}
                  size="lg"
                  className="text-pink-600 mr-3"
                  fixedWidth
                />
                <p className="text-gray-500">
                  <span className="font-bold text-xl text-gray-800 mr-1">{contact.ukPhone}</span>
                  UK
                </p>
              </div>
              <div className="flex items-center text-sm">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  size="lg"
                  className="text-pink-600 mr-3"
                  fixedWidth
                />
                <p>{contact.locations}</p>
              </div>
              <div className="flex items-center text-sm">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="lg"
                  className="text-pink-600 mr-3"
                  fixedWidth
                />
                <p>
                  <Link
                    href={`mailto:${contact.email}`}
                    className="text-gray-800 underline hover:no-underline"
                  >
                    {contact.email}
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <ContactForm id={contact.formId} />
        </div>
      </div>
    </div>
  );
};

Contact.propTypes = {};

Contact.defaultProps = {};

export default Contact;
