import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { scroller } from 'react-scroll';

import { useSite } from 'lib/context';
import Button from './Button';

const Hero = () => {
  const { hero } = useSite();

  return (
    <div className="relative">
      <div className="absolute inset-0">
        <img
          src={hero.bgImage.sourceUrl}
          alt={hero.bgImage.altText}
          className="block w-full h-full filter saturate-0 object-center object-cover"
        />
      </div>
      <div className="absolute h-full w-full bg-white bg-opacity-80" />
      <div className="relative min-h-screen flex items-center pt-24">
        <div className="container py-16">
          <div className="max-w-lg">
            <p className="text-2xl sm:text-3xl font-bold leading-snug sm:leading-snug">
              {hero.content}
            </p>
            <Button
              type="button"
              color="pink"
              className="mt-12"
              onClick={() =>
                scroller.scrollTo('about', {
                  duration: 1200,
                  offset: -60,
                  smooth: true,
                })
              }
            >
              Read more about us
              <FontAwesomeIcon icon={faLongArrowRight} size="1x" className="ml-3" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
