import React from 'react';

import { useSite } from 'lib/context';
import Link from './Link';
import ScrollLink from './ScrollLink';
import Logo from '../svg/logo.svg';

const Header = () => {
  const { hero } = useSite();

  return (
    <header className="py-8 absolute top-0 inset-x-0 z-10">
      <div className="container">
        <div className="flex justify-between items-center">
          <Logo className="block w-32 sm:w-40" />
          <nav className="flex items-center gap-x-10">
            <ScrollLink
              href="#about"
              className="hidden lg:inline-block p-1 font-bold text-gray-800 border-b-2 border-transparent hover:border-pink-600 transition-colors duration-200"
            >
              About
            </ScrollLink>
            <ScrollLink
              href="#services"
              className="hidden lg:inline-block p-1 font-bold text-gray-800 border-b-2 border-transparent hover:border-pink-600 transition-colors duration-200"
            >
              Services
            </ScrollLink>
            <ScrollLink
              href="#team"
              className="hidden lg:inline-block p-1 font-bold text-gray-800 border-b-2 border-transparent hover:border-pink-600 transition-colors duration-200"
            >
              Team
            </ScrollLink>
            <ScrollLink
              href="#contact"
              className="hidden lg:inline-block p-1 font-bold text-gray-800 border-b-2 border-transparent hover:border-pink-600 transition-colors duration-200"
            >
              Contact Us
            </ScrollLink>
            {hero.clientPortalUrl && (
              <Link
                href={hero.clientPortalUrl}
                className="p-1 font-bold text-gray-800 border-b-2 border-transparent hover:border-pink-600 transition-colors duration-200"
              >
                Client Portal
              </Link>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
