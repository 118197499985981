import PropTypes from 'prop-types';
import cx from 'classnames';

const Button = ({ as: Component, color, outline, className, children, ...props }) => (
  <Component
    className={cx(
      'inline-flex items-center rounded-full border font-bold py-4 px-8 transition-all duration-200',
      {
        'bg-pink-500 border-pink-500 text-white hover:bg-pink-700 hover:border-pink-700':
          color === 'pink' && !outline,
        'border-pink-500 text-pink-500 hover:border-pink-700 hover:text-pink-700':
          color === 'pink' && outline,

        'bg-white border-white text-gray-800 hover:bg-gray-100 hover:border-gray-100':
          color === 'white' && !outline,
        'border-white text-white hover:opacity-75': color === 'white' && outline,
      },
      className
    )}
    {...props}
  >
    {children}
  </Component>
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  color: PropTypes.oneOf(['pink', 'white']),
  outline: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  as: 'button',
  color: 'pink',
  outline: false,
  className: '',
  children: null,
};

export default Button;
