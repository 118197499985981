import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

export const SiteContext = createContext(null);

export const SiteProvider = ({ children, ...props }) => (
  <SiteContext.Provider value={props}>{children}</SiteContext.Provider>
);

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SiteConsumer = SiteContext.Consumer;

export const useSite = () => useContext(SiteContext);
