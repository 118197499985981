import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { scroller } from 'react-scroll';

import { useSite } from 'lib/context';
import Button from './Button';

const About = () => {
  const { about } = useSite();

  return (
    <div className="flex bg-pink-500" id="about">
      <div className="hidden lg:block w-1/2 bg-black bg-opacity-30">
        <img
          src={about.bgImage.sourceUrl}
          alt={about.bgImage.altText}
          className="block w-full h-full object-center object-cover mix-blend-multiply opacity-40"
        />
      </div>
      <div className="lg:w-1/2 container py-16 flex justify-center items-center">
        <div className="max-w-xl">
          <h2 className="text-2xl text-white font-bold">About us</h2>
          <p className="text-white text-xl leading-relaxed mt-8">{about.content}</p>
          <Button
            type="button"
            color="white"
            className="mt-8"
            onClick={() =>
              scroller.scrollTo('services', {
                duration: 1200,
                offset: -60,
                smooth: true,
              })
            }
            outline
          >
            Check our services
            <FontAwesomeIcon icon={faLongArrowRight} size="1x" className="ml-3" />
          </Button>
        </div>
      </div>
    </div>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default About;
